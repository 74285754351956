.competence {
  height: auto;
  position: relative;
  z-index: 9;
  .competence-body {
    max-width: 1340px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    .list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @include sm {
        top: 0;
        padding-top: 2rem;
      }
      span {
        max-height: 3.2rem;
        font-size: 1.3rem;
        font-weight: 700;
        padding: 0.6rem 1rem;
        border: 1px solid #e3e3e3;
        border-radius: 6px;
        margin: 0.5rem;
      }
    }
  }
}
.description {
  // margin-right: 4rem;
  h2 {
    font-size: 2.8rem;
    font-weight: 700;
    span {
      background: linear-gradient(to right, #59bbf1, #8c64ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p {
    font-weight: 300;
    font-size: 1.7rem;
  }
}
