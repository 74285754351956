footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  h2 {
    font-size: 2.5rem;
    font-weight: 400;
    color: #fff;
  }
  .iconify {
    color: #fff;
    font-size: 1.5rem;
    margin-right: 3rem;
  }
}
