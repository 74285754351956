/* 
* =========================
* TEXT
* =========================
*/
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}

.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}

.h6,
h6 {
  font-size: 1rem;
}

.text-center {
  text-align: center !important;
}
