.form-container {
  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
    label {
      color: rgba(255, 255, 255, 0.04);
      font-size: 22px;
      font-weight: 300;
    }
    .form-input {
      border: none;
      background: transparent;
      border-bottom: 1px solid rgba(243, 243, 243, 0.29);
      width: 100%;
      height: 4.3rem;
      padding: 1rem;
      color: rgba(255, 255, 255, 10%);
      font-size: 27px;
      font-weight: 500;
    }
    input::placeholder {
      color: rgba(255, 255, 255, 10%);
      font-size: 27px;
      font-weight: 500;
    }
    input:focus {
      outline: 0;
    }
  }
  .form-btn {
    width: 100%;
    height: 3.5rem;
    border: none;
    background: #1d1d1d;
    font-size: 1.5rem;
    border: 1px solid rgba(243, 243, 243, 0.29);
    font-weight: 600;
    color: rgba(243, 243, 243, 0.29);
    border-radius: 5px;
  }
}
