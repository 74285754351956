/* 
* =========================
* NAV
* =========================
*/
.topbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  width: 100%;
  padding: 0rem 15rem;
  height: 8rem;
  @include sm {
    justify-content: inherit;
    padding: 0;
  }
  .topbar-logo {
    a {
      color: var(--text);
      text-decoration: none;
      cursor: pointer;
    }
    .logo-title {
      color: white;
      font-family: var(--font-logo);
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: 1rem;
      @include sm {
        font-size: 2rem;
      }
    }
  }

  .link {
    list-style: none;
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    font-family: var(--font-secondary);
    text-decoration: none;
  }
  .link:hover {
    background: linear-gradient(to right, #64ffda, #59bbf1, #8c64ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .topbar-nav ul {
    display: flex;
    height: 3rem;
    @include sm {
      display: none;
    }
    ul,
    li {
      margin-right: 4rem;
      text-decoration: none;
      list-style: none;
    }

    button {
      padding: 0.5rem 2rem;
      border: none;
      border-radius: 5px;
      background-color: #fff;
      color: #000;
      list-style: none;
      font-size: 1rem;
      font-weight: 600;
      position: relative;
      bottom: 0.4rem;
    }
    button:hover {
      background-color: rgb(235, 235, 235);
    }
  }
  .drawer-mobile {
    @include sm {
      display: block;
    }
    @include xl {
      display: none;
    }
    .icon-drawer {
      width: 2.5rem;
      height: 3rem;
      color: white;
      top: -8px;
      position: relative;
    }

    .link {
      list-style: none;
      font-size: 1.4rem;
      font-weight: 600;
      font-family: var(--font-secondary);
      text-decoration: none;
    }
    .link:hover {
      background: linear-gradient(to right, #64ffda, #59bbf1, #8c64ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
