.contact {
  width: 1220px;
  @include sm {
    width: auto;
  }
  margin-left: auto;
  margin-right: auto;
  .contact-title {
    max-width: 1700px;
    margin-right: 0;
    margin-left: auto;
    display: flex;
    top: 1.2rem;
    position: relative;
    h2 {
      font-style: normal;
      font-weight: 900;
      font-size: 12rem;
      line-height: 242px;
      color: rgba(255, 255, 255, 0.04);
      @include sm {
        font-size: 5rem;
      }
    }
  }
  .contact-body {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
