.intro {
  height: 85vh;
  @include sm {
    height: 65vh;
  }
  .intro-container {
    color: #fff;
    height: auto;
    width: 58rem;
    margin-left: auto;
    margin-right: auto;
    top: 10rem;
    position: relative;
    @include sm {
      width: auto;
      top: 0;
    }
    .intro-body {
      position: relative;
      z-index: 7;
      padding: 1rem;
    }
    .intro-color {
      width: 47rem;
      height: 47rem;
      position: absolute;
      left: 4rem;
      bottom: -23rem;
      z-index: 1;
      @include sm {
        width: auto;
      }
      .color1 {
        position: absolute;
        width: 487px;
        height: 476px;
        left: 389px;
        top: 8px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(89, 187, 241, 0.39) 0%,
          rgba(58, 58, 58, 0) 100%
        );
        @include sm {
          left: 0;
          width: 287px;
          height: 276px;
        }
      }
      .color2 {
        position: absolute;
        width: 434px;
        height: 423px;
        left: 79px;
        top: 52px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(140, 100, 255, 0.49) 0%,
          rgba(58, 58, 58, 0) 100%
        );
        @include sm {
          width: 234px;
          height: 223px;
        }
      }
      .color3 {
        position: absolute;
        width: 354px;
        height: 363px;
        left: 327px;
        top: 192px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(100, 255, 218, 0.49) 0%,
          rgba(58, 58, 58, 0) 100%
        );
        @include sm {
          left: 0;
          width: 154px;
          height: 163px;
        }
      }
    }
    h1 {
      font-size: 3.3rem;
      font-weight: 800;
      margin-bottom: 2.5rem;
      @include sm {
        font-size: 2.3rem;
      }
    }

    .intro-footer {
      span {
        font-size: 2.1rem;
        font-weight: 300;
        letter-spacing: 5px;
      }
      .ligne-horizotal {
        bottom: 2.1rem;
        left: 28rem;
        background: #fff;
        width: 30rem;
        height: 0.05rem;
        position: absolute;
        @include sm {
          width: auto;
        }
      }
    }
  }
  .balise-container {
    height: 30rem;
    right: 3rem;
    display: grid;
    grid-template-areas:
      "auto"
      "auto"
      "auto"
      "auto";
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.17);
    position: absolute;
    top: 17rem;
  }
}
