/* Landscape phones and down */
@mixin xs {
  @media (max-width: 480px) {
    @content;
  }
}

/* Landscape phone to portrait tablet */
@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}

/* Portrait tablet to landscape and desktop */
@mixin md {
  @media (min-width: 768px) and (max-width: 979px) {
    @content;
  }
}

/* Large desktop */
@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}
