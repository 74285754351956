.share {
  position: fixed;
  display: grid;
  grid-template-areas:
    "auto"
    "auto"
    "auto";
  bottom: 9rem;
  margin-left: 3rem;
  .icon {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .position-ligne-vertical {
    top: 6.9rem;
    left: 0.7rem;
    height: 9rem;
    position: absolute;
  }

  a {
    color: #fff;
  }
  a:hover {
    color: rgb(161, 161, 161);
  }
}
