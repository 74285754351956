.projets {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .projets-title {
    max-width: 1700px;
    @include sm {
      max-width: auto;
    }
    margin-right: 0;
    margin-left: auto;
    display: flex;
    top: 1.2rem;
    position: relative;
    h2 {
      font-style: normal;
      font-weight: 900;
      font-size: 12rem;
      line-height: 242px;
      color: rgba(255, 255, 255, 0.04);
      @include sm {
        font-size: 5rem;
      }
    }
    .position-ligne-horizontal {
      top: 9.5rem;
      left: 0.7rem;
      width: 58%;
      @include sm {
        width: auto;
      }
      left: 0.7rem;
      position: relative;
    }
  }
  .projets-body {
    .projets-img {
      //   width: 100;
      //   object-fit: cover;
      //   border: 1px solid #f5f5f5;
      //   border-radius: 25px;
      //   height: 100%;
      //   width: 100%;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   left: 0;
      //   bottom: 0;
    }
  }
}
