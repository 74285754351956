.add_bottom_5 {
  margin-bottom: 5px;
}
.add_bottom_10 {
  margin-bottom: 10px;
}
.add_bottom_15 {
  margin-bottom: 15px;
}
.add_bottom_20 {
  margin-bottom: 20px;
}
.add_bottom_25 {
  margin-bottom: 25px;
}
.add_bottom_30 {
  margin-bottom: 30px;
}
.add_bottom_45 {
  margin-bottom: 45px;
}
.add_bottom_50 {
  margin-bottom: 50px;
}
.add_bottom_60 {
  margin-bottom: 60px;
}
.add_bottom_75 {
  margin-bottom: 75px;
}
.add_bottom_90 {
  margin-bottom: 90px;
}
.add_bottom_105 {
  margin-bottom: 105px;
}
.add_top_5 {
  margin-top: 5px;
}
.add_top_10 {
  margin-top: 10px;
}
.add_top_15 {
  margin-top: 15px;
}
.add_top_20 {
  margin-top: 20px;
}
.add_top_30 {
  margin-top: 30px;
}
.add_top_45 {
  margin-top: 30px;
}
.add_top_60 {
  margin-top: 60px;
}
.add_top_80 {
  margin-top: 80px;
}
.more_padding_left {
  padding-left: 40px;
}
.nomargin_top {
  margin-top: 0;
}
.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}
.nomargin {
  margin: 0 !important;
}
.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}
@media (max-width: 575px) {
  .margin_60_35 {
    padding-top: 30px;
    padding-bottom: 5px;
  }
}
.margin_50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 991px) {
  .margin_50 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
@media (max-width: 575px) {
  .margin_50 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.margin_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991px) {
  .margin_30 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
@media (max-width: 575px) {
  .margin_30 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.margin_40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 991px) {
  .margin_40 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
@media (max-width: 575px) {
  .margin_40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.margin_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 991px) {
  .margin_80 {
    padding-bottom: 70px;
    padding-top: 70px;
  }
}
@media (max-width: 575px) {
  .margin_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.margin_130 {
  padding-top: 130px;
  padding-bottom: 130px;
}
@media (max-width: 991px) {
  .margin_130 {
    padding-bottom: 110px;
    padding-top: 110px;
  }
}
@media (max-width: 575px) {
  .margin_130 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}
.margin_160_130 {
  padding-top: 160px;
  padding-bottom: 130px;
}
@media (max-width: 991px) {
  .margin_160_130 {
    padding-top: 140px;
    padding-bottom: 110x;
  }
}
@media (max-width: 575px) {
  .margin_160_130 {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}
.margin_80_55 {
  padding-top: 80px;
  padding-bottom: 55px;
}
@media (max-width: 991px) {
  .margin_80_55 {
    padding-top: 60px;
    padding-bottom: 45px;
  }
}
@media (max-width: 575px) {
  .margin_80_55 {
    padding-top: 40px;
    padding-bottom: 35px;
  }
}
.margin_80_0 {
  padding-top: 80px;
}
@media (max-width: 991px) {
  .margin_80_0 {
    padding-top: 80px;
  }
}
@media (max-width: 575px) {
  .margin_80_0 {
    padding-top: 80px;
  }
}
.margin_0_80 {
  padding-bottom: 80px;
}
@media (max-width: 991px) {
  .margin_0_80 {
    padding-bottom: 80px;
  }
}
@media (max-width: 575px) {
  .margin_0_80 {
    padding-bottom: 80px;
  }
}
.margin_60_0 {
  padding-top: 60px;
}
@media (max-width: 991px) {
  .margin_60_0 {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_60_0 {
    padding-top: 45px;
  }
}
.margin_45_0 {
  padding-top: 45px;
}
@media (max-width: 991px) {
  .margin_45_0 {
    padding-top: 35px;
  }
}
@media (max-width: 575px) {
  .margin_45_0 {
    padding-top: 25px;
  }
}
.margin_60_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 991px) {
  .margin_60_60 {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}
@media (max-width: 575px) {
  .margin_60_60 {
    padding-top: 35px;
    padding-bottom: 60px;
  }
}
.margin_35_35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
@media (max-width: 991px) {
  .margin_35_35 {
    padding-top: 25px;
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .margin_35_35 {
    padding-top: 15px;
    padding-bottom: 5px;
  }
}
.margin_45_60 {
  padding-top: 45px;
  padding-bottom: 60px;
}
@media (max-width: 991px) {
  .margin_45_60 {
    padding-top: 35px;
    padding-bottom: 45px;
  }
}
@media (max-width: 575px) {
  .margin_45_60 {
    padding-top: 25px;
    padding-bottom: 45px;
  }
}
.margin_30_95 {
  padding-top: 30px;
  padding-bottom: 95px;
}
@media (max-width: 991px) {
  .margin_30_95 {
    padding-top: 15px;
    padding-bottom: 35px;
  }
}
@media (max-width: 575px) {
  .margin_30_95 {
    padding-top: 5px;
    padding-bottom: 30px;
  }
}
