.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext-bottom {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 50%;
    margin-left: -40px;
    opacity: 0.7;
  }
  .tooltiptext-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
    opacity: 0.7;
  }

  .tooltiptext-top {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0.7;
  }

  .tooltiptext-top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    opacity: 0.7;
  }
}

.tooltip:hover .tooltiptext-top,
.tooltip:hover .tooltiptext-bottom {
  visibility: visible;
}
